import { Trans } from "@lingui/react/macro";
import {
	CardContent,
	CardFooter,
	CardHeader,
	WhiteCard,
} from "@/components/ui/card";
import {
	useFullNameParticipant,
	useUserIdQuery,
} from "src/state/react-query/useUserInfoQuery";
import { MessageOptionsMenu } from "../Questions/MessageOptionsMenu";
import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useEditMessage } from "src/state/react-query/useQuestions";
import { Upvote } from "../Upvote";
import type { Message } from "types/bindings/Message";
import { useDayjs } from "src/utils/customDayjs";

type DisplayReplyProps = {
	reply: Message;
};

export const DisplayReply = ({ reply }: DisplayReplyProps) => {
	const { data: currentUserId } = useUserIdQuery();
	const { data: userName } = useFullNameParticipant({
		userId: currentUserId,
	});
	const [showInput, setShowInput] = useState(false);
	const [newReply, setNewReply] = useState<string | undefined>(undefined);
	const editReply = useEditMessage();
	const dayjs = useDayjs((state) => state.dayjs);

	const handleReplyEdit = () => {
		if (newReply)
			editReply.mutate({ newMessage: newReply, messageId: reply.id });
		setShowInput(false);
	};

	const handleEnter: React.KeyboardEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (e) => {
		if (e.key === "Enter") {
			handleReplyEdit();
			setShowInput(false);
		}
	};

	const getNameParticipant = () => {
		if (!reply.anonymous && userName) return userName.fullName;
		return <Trans>Anonymous</Trans>;
	};

	return (
		<WhiteCard variant="inner">
			<CardHeader>
				<div className="flex ml-auto self-end text-xs text-gray-500">
					<Trans>Posted</Trans> {dayjs.utc(reply.created_at).fromNow()}{" "}
					<Trans>by</Trans> {getNameParticipant()}
				</div>
			</CardHeader>
			<CardContent>
				<div>
					{showInput ? (
						<>
							<Textarea
								className="text-black my-3 w-3/4 mx-auto"
								onChange={(e) => setNewReply(e.target.value)}
								defaultValue={reply.content}
								onKeyDown={(e) => handleEnter(e)}
							/>
							<div dir="rtl">
								<Button onClick={handleReplyEdit} className="w-28 me-8">
									<Trans>Post</Trans>
								</Button>
							</div>
						</>
					) : (
						<div className=" mt-3 " data-testid="reply-content">
							{reply.content}
						</div>
					)}
				</div>
			</CardContent>
			<CardFooter className="text-gray-500 flex mt-3">
				<div className="float-left mr-1">
					<Upvote
						messageId={reply.id}
						upvotes={reply.upvotes}
						upvoted_by_user={reply.upvoted_by_user}
					/>
					{currentUserId === reply.user_id && (
						<MessageOptionsMenu
							setShowInput={setShowInput}
							isMessageAuthor
							isCreator={false}
							{...reply}
						/>
					)}
				</div>
			</CardFooter>
		</WhiteCard>
	);
};
