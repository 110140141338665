import { Trans } from "@lingui/react/macro";
import { buttonVariants } from "@/components/ui/button";
import {
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import {
	type RoomOfUser,
	useArchiveRooms,
} from "src/state/react-query/useRooms";

export const ArchivePromptRoom = ({
	room_number,
	name,
	userId,
}: RoomOfUser & { userId: string }) => {
	const archiveRoom = useArchiveRooms({ room_number, userId });

	const handleArchive = () => {
		archiveRoom.mutate();
	};
	return (
		<DialogContent>
			<DialogHeader>
				<DialogTitle>
					<Trans>Are you sure you want to archive this room?</Trans>
				</DialogTitle>
				<DialogDescription>
					<Trans>You are about to archive room</Trans> <strong> {name}</strong>
					<Trans>. This action cannot be undone.</Trans>
				</DialogDescription>
			</DialogHeader>
			<div className="flex justify-center items-center">
				<DialogClose
					className={`${cn(buttonVariants({ variant: "destructive" }))} m-2`}
					onClick={handleArchive}
				>
					<Trans>Archive</Trans>
				</DialogClose>
			</div>
		</DialogContent>
	);
};
