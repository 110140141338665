import { Question } from "./Question";

import { useAutoAnimate } from "@formkit/auto-animate/react";
import { type JSX, useEffect, useState } from "react";
import { sortOnVotesAndOnDate } from "src/utils/date_sort";
import type { Message } from "types/bindings/Message";

type QuestionListProps = {
	messages: Message[];
};

export function QuestionList({ messages }: QuestionListProps): JSX.Element {
	const [orderedMessages, setOrderedMessages] = useState(messages);
	useEffect(() => {
		setOrderedMessages(sortOnVotesAndOnDate(messages));
	}, [messages]);
	const [parent] = useAutoAnimate({
		duration: 250,
		easing: "ease-in-out",
		disrespectUserMotionPreference: false,
	});

	return (
		<li ref={parent} className="list-none">
			{orderedMessages
				.filter((q) => q.parent_id === null && q.archived_at === null)
				.map(
					(message): JSX.Element => (
						<Question
							key={message.id}
							{...{ message, messages: orderedMessages }}
						/>
					),
				)}
		</li>
	);
}
