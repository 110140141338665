import {
	CardContent,
	CardHeader,
	CardTitle,
	WhiteCard,
} from "@/components/ui/card";
import { Header } from "@/components/ui/header";
import { isOrgPageEnabled } from "../homePage";
import type { JSX } from "react";

export const OrgPage = (): JSX.Element | null => {
	if (isOrgPageEnabled) {
		return (
			<div>
				<Header title="Organisation Name" />

				<WhiteCard>
					<CardHeader>
						<CardTitle className="text-primary">People</CardTitle>
					</CardHeader>
					<CardContent>
						<li>users linked to that organisation</li>
					</CardContent>
				</WhiteCard>

				<WhiteCard>
					<CardHeader>
						<CardTitle className="text-primary">Events</CardTitle>
					</CardHeader>
					<CardContent>
						<li>events</li>
					</CardContent>
				</WhiteCard>
			</div>
		);
	}
	return null;
};
