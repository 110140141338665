import { Trans } from "@lingui/react/macro";
import { type JSX, useState } from "react";
import { Input } from "../../@/components/ui/input";
import {
	useAddFullName,
	useChangeEmail,
} from "../../state/react-query/useUserInfoQuery";
import {
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { NameInputField } from "../NameInputField";
import { buttonVariants } from "@/components/ui/button";
import { ErrorMessage } from "../ErrorMessage";
import { SignInButton } from "../SignInButton";
import { z } from "zod";
import { Alert } from "@/components/ui/alert";
import { cn } from "@/lib/utils";
import { useLingui } from "@lingui/react/macro";

export const UserSettings = ({
	userId,
	currentEmailUser,
	currentFullName,
}: {
	userId: string;
	currentEmailUser?: string;
	currentFullName: string;
}): JSX.Element => {
	const { t } = useLingui();
	const [localFullName, setFullName] = useState(currentFullName);
	const [localEmail, setLocalEmail] = useState(currentEmailUser);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const addFullName = useAddFullName({ userId });
	const changeEmail = useChangeEmail();
	const hasEmailChanged = localEmail !== currentEmailUser;
	const hasFullNameChanged = localFullName !== currentFullName;
	async function handleUpdateUserSettings() {
		if (hasFullNameChanged) {
			await addFullName.mutateAsync({ fullName: localFullName, userId });
		}
		if (hasEmailChanged) {
			const emailSchema = z.string().email();
			if (!emailSchema.safeParse(localEmail).success)
				setErrorMessage(
					t`Invalid email address. Please fill in a valid email address.`,
				);
			else setErrorMessage(undefined);
			await changeEmail.mutateAsync({ newEmail: localEmail ?? "" });
		}
		return;
	}

	const handleKeyDown = async (
		event: React.KeyboardEvent<HTMLInputElement>,
	) => {
		if (event.key === "Enter") {
			await handleUpdateUserSettings();
		}
	};

	return (
		<DialogContent>
			<DialogHeader>
				<DialogTitle>
					<strong>
						<Trans>User settings</Trans>
					</strong>
				</DialogTitle>
			</DialogHeader>
			{changeEmail.isError && (
				<div className="flex flex-col items-center">
					<ErrorMessage
						errorMessage={
							errorMessage ??
							t`An account with this email address already exists. Please sign in.`
						}
					/>
					{!errorMessage && <SignInButton />}
				</div>
			)}
			{changeEmail.isSuccess && (
				<Alert className="my-4 mx-auto text-primary border-secondary">
					<strong>
						<Trans>
							Your email has been successfully added! Please check your inbox
							for a link to your account.
						</Trans>
					</strong>
				</Alert>
			)}
			<div className="grid w-full max-w-sm items-center ">
				<Label className="text-black">
					<Trans>Name</Trans>
				</Label>
				<NameInputField
					setFullName={setFullName}
					userId={userId}
					handleKeyDown={() => handleKeyDown}
					className="my-3"
					fullName={localFullName}
				/>
			</div>
			<div className="grid w-full max-w-sm items-center mt-3">
				<Label className="text-black">
					<Trans>Email</Trans>
				</Label>
				<Input
					className="my-3"
					placeholder={t`Enter your email`}
					onChange={(e) => setLocalEmail(e.target.value)}
					onKeyDown={(e) => handleKeyDown(e)}
					value={localEmail}
				/>
			</div>
			<div className="flex justify-center items-center">
				<DialogClose
					className={`${cn(buttonVariants())}`}
					disabled={!hasEmailChanged && !hasFullNameChanged}
					onClick={handleUpdateUserSettings}
				>
					<Trans>Submit</Trans>
				</DialogClose>
			</div>
		</DialogContent>
	);
};
