import { useUserInfoQuery } from "../../state/react-query/useUserInfoQuery";
import { UserNav } from "./UserNav";
import { SignInButton } from "../SignInButton";
import { LanguageSetting } from "./LanguageSetting";
import { backgroundColor } from "src/tailwind/tailwindConstants";
import type { JSX } from "react";

export const TopBar = (): JSX.Element => {
	const { data, isPending: isPendingUserInfo } = useUserInfoQuery();
	return (
		<div className={`grid md:grid-cols-9 grid-cols-3 ${backgroundColor}`}>
			<a href="/">
				{" "}
				<img
					src="/questif_logo_rounded.png"
					alt="questif-logo"
					className="object-scale-down h-16 w-16 cursor-pointer md:ml-5 ml-3 mt-4 mb-3"
				/>{" "}
			</a>
			<div className="md:col-start-5 flex justify-center mt-7 mx-auto">
				<a
					href="/"
					className=" font-sans font-bold text-3xl text-white cursor-pointer"
				>
					questif
				</a>
			</div>
			<div className="flex justify-end self-center md:col-start-9 md:mr-5 mr-3">
				<div className="mr-2">
					<LanguageSetting />
				</div>
				<div className="">
					{data?.user ? (
						<UserNav userData={data.user} />
					) : isPendingUserInfo ? null : (
						<SignInButton isIcon />
					)}
				</div>
			</div>
		</div>
	);
};
