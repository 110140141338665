import { Trans } from "@lingui/react/macro";
import {
	type RoomOfUser,
	useRoomsOfUser,
} from "src/state/react-query/useRooms";
import { MyRoom } from "./MyRoom";
import { useNavigate } from "@tanstack/react-router";
import { useDayjs } from "src/utils/customDayjs";
import { CardContent, ColorFilledCard } from "@/components/ui/card";
import { roomRoute } from "src/routes/routes";

export const RoomsCards = ({
	userId,
	isMyRooms = false,
}: { userId: string; isMyRooms?: boolean }) => {
	const { data, isError, isLoading } = useRoomsOfUser({ userId });
	if (isError)
		return (
			<p>
				<Trans>Error!</Trans>
			</p>
		);
	if (isLoading)
		return (
			<p>
				<Trans>Loading...</Trans>
			</p>
		);
	const activeRooms = data?.filter((room) => room.archived_at === null) || [];
	const createdRooms =
		activeRooms?.filter((room) => room.users_rooms[0].role === "creator") || [];

	return (
		<div className="flex flex-col w-full space-y-4 mt-4">
			{isMyRooms &&
				createdRooms.length > 0 &&
				createdRooms?.map((room) => (
					<MyRoom {...room} userId={userId} key={userId} />
				))}
			{!isMyRooms &&
				activeRooms.length > 0 &&
				activeRooms?.map((room) => (
					<GeneralRoom {...room} userId={userId} key={userId} />
				))}
		</div>
	);
};

const GeneralRoom = ({ userId, ...room }: RoomOfUser & { userId: string }) => {
	const { name, room_number, created_at } = room;
	const navigate = useNavigate();
	const useDateFromNow = useDayjs((state) => state.getDateFromNow(created_at));
	const shortenedName = name.length > 35 ? `${name.slice(0, 35)}...` : name;

	return (
		<ColorFilledCard
			onClick={() =>
				navigate({
					to: roomRoute.to,
					params: { roomNumber: room_number },
				})
			}
			className="text-white bg-secondary drop-shadow-md hover:bg-secondary-dark cursor-pointer"
		>
			<CardContent className="flex-col p-2">
				<p className="font-bold text-md">{shortenedName}</p>
				<div className="flex justify-between text-sm">
					<p># {room_number}</p>
					<Trans>created </Trans> {useDateFromNow}
				</div>
			</CardContent>
		</ColorFilledCard>
	);
};
