import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import * as Supabase from "src/supabaseClient";

const defaultPath = "/";
const redirectTo = `${origin}${defaultPath}`;

export const UserLogin = () => {
	const fonts = {
		bodyFontFamily: "Montserrat Variable",
		buttonFontFamily: "Montserrat Variable",
		inputFontFamily: "Montserrat Variable",
		labelFontFamily: "Montserrat Variable",
	};
	return (
		<div className="w-full">
			<Auth
				supabaseClient={Supabase.client}
				appearance={{ theme: ThemeSupa, variables: { default: { fonts } } }}
				providers={["google"]}
				view={"magic_link"}
				redirectTo={redirectTo}
				magicLink
				showLinks={false}
			/>
		</div>
	);
};
