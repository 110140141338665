import { Trans } from "@lingui/react/macro";
import { useUserIdQuery } from "src/state/react-query/useUserInfoQuery";
import {
	useChangeVote,
	useDeleteVote,
} from "src/state/react-query/useUserVoteQuestion";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import type { Message } from "types/bindings/Message";

type UpvoteProps = {
	upvotes: number;
	messageId: string;
} & Pick<Message, "upvoted_by_user">;

export const Upvote = ({
	upvotes,
	messageId,
	upvoted_by_user,
}: UpvoteProps) => {
	const { data: userId } = useUserIdQuery();
	// const { data: voteState } = useUserVoteQuestion({
	// 	questionId: messageId,
	// 	userId,
	// });
	const upvoteToolTipText = upvoted_by_user ? (
		<Trans>Remove upvote</Trans>
	) : (
		<Trans>Upvote</Trans>
	);
	const UpvoteIcon = upvoted_by_user ? AiFillLike : AiOutlineLike;
	const removeVote = useDeleteVote({ userId, messageId });
	const modifyVote = useChangeVote({ userId, messageId });
	return (
		<div className="float-left">
			<Tooltip>
				<TooltipTrigger
					className={`${cn(buttonVariants({ variant: upvoted_by_user ? "outlinePressed" : "outline", size: "icon" }))}`}
					onClick={() => {
						if (upvoted_by_user) {
							return removeVote.mutate();
						}
						modifyVote.mutate({ vote_kind: "upvote" });
					}}
					data-testid="upvote-button"
				>
					{/* TODO Add VoteCountSlider component, see issue #100 on GitHub.*/}
					<p
						className="float-left mx-1 my-auto text-xs"
						data-testid="amount-of-upvotes"
					>
						{upvotes}
					</p>
					<UpvoteIcon
						className={`float-left mb-1 text-xs ${upvoted_by_user && "text-primary"}`}
					/>
				</TooltipTrigger>
				<TooltipContent>
					<p className="text-xs">{upvoteToolTipText}</p>
				</TooltipContent>
			</Tooltip>
		</div>
	);
};
