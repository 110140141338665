import { Trans } from "@lingui/react/macro";
import { buttonVariants } from "@/components/ui/button";
import {
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { useDeleteMessage } from "src/state/react-query/useQuestions";
import { cn } from "@/lib/utils";
import type { Message } from "types/bindings/Message";
import type { JSX } from "react";

export const DeletePromptMessage = (message: Message): JSX.Element => {
	const { id: questionId } = message;
	const deleteQuestion = useDeleteMessage();

	const handleDelete = () => {
		deleteQuestion.mutate({ questionId });
	};
	return (
		<DialogContent>
			<DialogHeader>
				<DialogTitle>
					<Trans>Are you sure you want to delete this question?</Trans>
				</DialogTitle>
				<DialogDescription>
					<Trans>
						This action cannot be undone. This will permanently delete this
						question and its data from our server.
					</Trans>
				</DialogDescription>
			</DialogHeader>
			<div className="flex justify-center items-center">
				<DialogClose
					className={`${cn(buttonVariants({ variant: "destructive" }))}`}
					onClick={handleDelete}
				>
					<Trans>Delete</Trans>
				</DialogClose>
			</div>
		</DialogContent>
	);
};
