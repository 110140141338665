import { Trans } from "@lingui/react/macro";
import {
	WhiteCard,
	CardHeader,
	CardTitle,
	CardFooter,
} from "@/components/ui/card";
import { SignInButton } from "src/components/SignInButton";

export const SignInToCreateRoom = () => {
	return (
		<WhiteCard>
			<CardHeader>
				<CardTitle className="text-primary float-right p-6">
					<Trans>Sign in to create a room</Trans>
				</CardTitle>
			</CardHeader>
			<CardFooter className="p-6 pt-0">
				<SignInButton className="mx-auto " />
			</CardFooter>
		</WhiteCard>
	);
};
