import { Trans } from "@lingui/react/macro";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState } from "react";
import { MdLanguage } from "react-icons/md";
import { switchLocale } from "src/i18n";

export const LanguageSetting = () => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	return (
		<DropdownMenu open={menuIsOpen} onOpenChange={setMenuIsOpen}>
			<DropdownMenuTrigger asChild>
				<Button
					variant={menuIsOpen ? "outlinePressed" : "outline"}
					className="relative rounded-full"
					size="icon"
				>
					<MdLanguage />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<p className="text-sm font-medium leading-none">
						<Trans>Language settings</Trans>
					</p>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />

				<DropdownMenuGroup>
					<DropdownMenuItem
						className="cursor-pointer"
						onClick={() => {
							switchLocale("en");
						}}
					>
						<div className="flex flex-col space-y-1">English</div>
					</DropdownMenuItem>
					<DropdownMenuItem
						className="cursor-pointer"
						onClick={() => {
							switchLocale("es");
						}}
					>
						Español
					</DropdownMenuItem>
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
