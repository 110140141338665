import {
	CardContent,
	CardHeader,
	CardTitle,
	WhiteCard,
} from "@/components/ui/card";
import { UserLogin } from "src/components/Auth/UserLogin";
export const SignInPage = () => {
	return (
		<WhiteCard className=" justify-center">
			<CardHeader className="max-w-md m-auto p-6">
				<CardTitle data-testid="sign-in" className="text-primary font-bold">
					Sign in
				</CardTitle>
			</CardHeader>
			<CardContent className="w-full p-6 pt-0">
				<UserLogin />
			</CardContent>
		</WhiteCard>
	);
};
