import { create } from "zustand";

type UseCreateRoomDialog = {
	createRoomDialogIsOpen: boolean;
	setCreateRoomDialogIsOpen: (a: boolean) => void;
};

export const useCreateRoomDialog = create<UseCreateRoomDialog>((set) => ({
	createRoomDialogIsOpen: false,
	setCreateRoomDialogIsOpen: (createRoomDialogIsOpen) =>
		set({ createRoomDialogIsOpen }),
}));

type UseCreateOrgDialog = {
	createOrgDialogIsOpen: boolean;
	setCreateOrgDialogIsOpen: (a: boolean) => void;
};

export const useCreateOrgDialog = create<UseCreateOrgDialog>((set) => ({
	createOrgDialogIsOpen: false,
	setCreateOrgDialogIsOpen: (createOrgDialogIsOpen) =>
		set({ createOrgDialogIsOpen }),
}));
