import { Separator } from "@/components/ui/Separator";
import { SeparatorWithContent } from "../../@/components/ui/SeparatorWithContent";
import { JoinRoomCard } from "./components/JoinRoom/JoinRoomCard";
import { OrgList } from "./components/Org/OrgList";
import { RoomsList } from "./components/RoomsList/RoomsList";

export const isOrgPageEnabled = !import.meta.env.PROD;

export function Index() {
	return (
		<div className="justify-center">
			<JoinRoomCard />
			<div className="relative flex py-5 items-center max-w-xl w-10/12 m-auto mt-8">
				<SeparatorWithContent content="Or" />
			</div>
			<RoomsList />
			{isOrgPageEnabled && (
				<>
					<div className="relative flex py-5 items-center max-w-xl w-10/12 m-auto mt-8">
						<Separator />
					</div>
					<OrgList />{" "}
				</>
			)}
		</div>
	);
}
