import { TopBar } from "./TopBar/TopBar";
import { Outlet } from "@tanstack/react-router";
import React, { type JSX } from "react";
import { Toaster } from "@/components/ui/sonner";

const TanStackRouterDevtools = import.meta.env.PROD
	? () => null // Render nothing in production
	: React.lazy(() =>
			// Lazy load in development
			import("@tanstack/router-devtools").then((res) => ({
				default: res.TanStackRouterDevtools,
				// For Embedded Mode
				// default: res.TanStackRouterDevtoolsPanel
			})),
		);

export const RootComponent = (): JSX.Element => {
	return (
		<>
			<TopBar />
			<Outlet />
			<TanStackRouterDevtools position="bottom-left" initialIsOpen={false} />
			<Toaster />
		</>
	);
};
