import { useMutation, useQuery } from "@tanstack/react-query";
import * as Supabase from "../../supabaseClient";
import { roleOfRoom, roomsOfUser } from "./react-query-keys";
import { queryClient } from "./client";

export type UseRoomsProps = {
	userId: string;
};

export const useCreateRoom = ({ userId }: UseRoomsProps) => {
	return useMutation({
		mutationFn: ({ roomName }: { roomName: string }) =>
			Supabase.createRoom({ roomName }),
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser, userId],
			}),
	});
};

export type RoomOfUser = {
	id: string;
	created_at: string;
	archived_at: string | null;
	room_number: string;
	name: string;
	users_rooms: {
		id: number;
		role: "participant" | "creator";
		user_id: string;
	}[];
};

export const useRoomsOfUser = ({ userId }: UseRoomsProps) => {
	return useQuery({
		queryKey: [roomsOfUser, userId],
		queryFn: async (): Promise<RoomOfUser[]> => {
			const data = (
				await Supabase.client
					.from("room")
					.select(
						"id,created_at,archived_at,room_number,name,users_rooms!inner(id,role,user_id)",
					)
					.order("created_at", { ascending: false })
					.eq("users_rooms.user_id", userId)
					.throwOnError()
			).data;
			return data !== null ? data : [];
		},
		enabled: !!userId,
		staleTime: 10_000,
	});
};

export const getRoleOfUser = ({
	userId,
	roomId,
}: { userId?: string; roomId?: string }) => {
	return useQuery({
		queryKey: [roleOfRoom, userId, roomId],
		queryFn: async () => {
			if (!userId || !roomId) {
				throw Error(
					`userId (${userId}) or roomId (${roomId}) is not defined. This should never happen because of the enabled flag.`,
				);
			}
			const data = (
				await Supabase.client
					.from("users_rooms")
					.select("role")
					.eq("user_id", userId)
					.eq("room_id", roomId)
					.limit(1)
					.single()
					.throwOnError()
			).data;
			return data;
		},
		enabled: !!userId || !!roomId,
		staleTime: 10_000,
	});
};

type UseDeleteRoomsProps = {
	room_number: string;
	userId: string;
};

export const useArchiveRooms = ({
	room_number,
	userId,
}: UseDeleteRoomsProps) => {
	return useMutation({
		mutationFn: () => Supabase.deleteRoom({ room_number }),
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser, userId],
			}),
	});
};

export const useChangeRoomName = ({
	room_number,
	userId,
}: UseDeleteRoomsProps) => {
	return useMutation({
		mutationFn: ({ name }: { name: string }) =>
			Supabase.updateRoom({ room_number, name }),
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser, userId],
			}),
	});
};
