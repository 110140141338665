import { Trans } from "@lingui/react/macro";
import {
	useIsAnonymousUser,
	useIsLoggedIn,
	useUserInfoQuery,
} from "src/state/react-query/useUserInfoQuery";
import {
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
	WhiteCard,
} from "@/components/ui/card";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { CreateRoomDialog } from "./CreateRoomDialog";
import { SignInToCreateRoom } from "./SignInToCreateRoom";
import { UserSettings } from "src/components/TopBar/UserSettings";
import { Alert } from "@/components/ui/alert";
import { useCreateRoomDialog } from "src/state/zustand/createDialogStore";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RoomsCards } from "./RoomsCards";
import type { JSX } from "react";
import { TbCirclePlusFilled } from "react-icons/tb";

export function RoomsList(): JSX.Element {
	const { createRoomDialogIsOpen, setCreateRoomDialogIsOpen } =
		useCreateRoomDialog();
	const userInfo = useUserInfoQuery();
	const { data: isLoggedIn } = useIsLoggedIn();
	const isAnonUser = useIsAnonymousUser();
	if (userInfo.isError) return <p>Error!</p>;
	if (userInfo.isPending) return <p>Loading...</p>;
	const userId = userInfo.data.user?.id;
	if (isLoggedIn && userId) {
		return (
			<WhiteCard>
				<CardHeader className="p-6">
					<CardTitle className="text-primary">
						<Trans>Rooms</Trans>
					</CardTitle>
				</CardHeader>

				<Tabs defaultValue="all-rooms" className="mx-1">
					<TabsList className="grid grid-cols-2">
						<TabsTrigger value="all-rooms" className="text-center">
							<p className="text-sm">
								<Trans>All Rooms</Trans>
							</p>
						</TabsTrigger>
						<TabsTrigger
							value="created"
							className="text-center"
							data-testid="my-rooms"
						>
							<p className="text-sm">
								<Trans>My Rooms</Trans>
							</p>
						</TabsTrigger>
					</TabsList>

					<TabsContent value="all-rooms">
						<CardContent className="pb-6">
							<RoomsCards userId={userId} />
						</CardContent>
					</TabsContent>
					<TabsContent value="created">
						<CardContent>
							<RoomsCards userId={userId} isMyRooms />
						</CardContent>
						<CardFooter className="flex flex-col items-center p-6 pt-0">
							{userId && isAnonUser ? (
								<Dialog>
									<DialogTrigger>
										<Alert className="hover:bg-slate-200 cursor-pointer text-center mt-2">
											<Trans>Add your email to create rooms!</Trans>
										</Alert>
									</DialogTrigger>
									<UserSettings
										userId={userId}
										currentEmailUser={userInfo.data.user?.email ?? ""}
										currentFullName={""}
									/>
								</Dialog>
							) : (
								<Dialog
									open={createRoomDialogIsOpen}
									onOpenChange={setCreateRoomDialogIsOpen}
								>
									<DialogTrigger>
										<TbCirclePlusFilled
											className="text-4xl mt-3 cursor-pointer text-primary drop-shadow-md hover:text-primary-dark"
											data-testid="open-create-room-dialog"
										/>
									</DialogTrigger>
									<CreateRoomDialog userId={userId} />
								</Dialog>
							)}
						</CardFooter>
					</TabsContent>
				</Tabs>
			</WhiteCard>
		);
	}
	return <SignInToCreateRoom />;
}
