import type { ReactNode } from "react";
import { backgroundColor } from "src/tailwind/tailwindConstants";

export const Header = ({
	children,
	title,
	subtitle,
}: { children?: ReactNode; title: string; subtitle?: string }) => {
	return (
		<div
			className={`flex flex-col justify-center drop-shadow-md ${backgroundColor} py-5`}
		>
			<h1
				className="text-xl text-center text-white font-sans font-bold"
				data-testid="title"
			>
				{title}
			</h1>
			{subtitle && (
				<h1 className="text-xl text-center text-white font-sans mx-5">
					{subtitle}
				</h1>
			)}
			{children}
		</div>
	);
};
