import {
	createRootRoute,
	createRoute,
	useParams,
} from "@tanstack/react-router";
import { RootComponent } from "src/components/RootComponent";
import { Index } from "src/pages/homePage";
import { RoomPage } from "src/pages/roomPage/roomPage";
import { SignInPage } from "src/pages/signInPage/signInPage";
import { OrgPage } from "../pages/orgPage/OrgPage";

export const routes = {
	rootRoute: "/",
	roomsListRoute: "/rooms",
	roomRoute: "/room/$roomNumber",
	signInRoute: "/sign-in",
	orgRoute: "/org/$orgId",
} as const;

export const rootRoute = createRootRoute({
	component: RootComponent,
	pendingComponent: () => <div>Loading...</div>,
});

export const indexRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.rootRoute,
	component: Index,
});

export const signInRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.signInRoute,
	component: SignInPage,
});

export const roomRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.roomRoute,
	component: () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { roomNumber } = useParams({ from: roomRoute.id });
		return <RoomPage roomNumber={roomNumber} />;
	},
});

export const orgRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: routes.orgRoute,
	component: OrgPage,
});
